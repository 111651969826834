<template>
  <div>
    <van-dialog 
    :visible='visible'
    :modeFrom="modeFrom" 
    :userToken="userToken"
    :show="show" 
    v-model="visible" 
    :showConfirmButton="false"
    :close-on-click-overlay="true"
    :getCodeTextShow="getCodeTextShow"
    @close="closeDialog"
    >
      <div class="topDialog"><van-icon name="cross" @click="visible = false" /></div>
      <div class="topDialogCode">请输入验证码</div>
      <div class="topDialogMain">
        <div class="topDialogMain-text">提现</div>
        <div class="topDialogMain-Count">¥{{costObject.amount }}</div>
      </div>
      <van-divider 
      :style="{  borderColor: '#EEEEEE', padding: '0 16px' }"
      />
      <div class="topDialogBottom">
        <div class="topDialogBottom-left">服务费</div>
        <div class="topDialogBottom-right">{{costObject.fee || '' }}</div>
      </div>
      <div class="topDialogBottom">
        <div class="topDialogBottom-left">费率</div>
        <div class="topDialogBottom-right" style="font-size: 12px;">{{costObject.proceduresCharge}}</div>
      </div>
      <div class="topDialogBottom">
        <div class="topDialogBottom-phone">{{ costObject.phone || '--' }}</div>
    
        <div 
        v-if="setCodeTextFlage"
        class="topDialogBottom-code"
        :class="setCodeTextShow? 'enable':'' "
              style="font-size: 12px; color: #ffa54f"
              @click="getCode"
        >获取验证码</div>
        <div 
        v-else
        style="display: block; width: 40px; color: #999999">
          {{ textContent }}
        </div>
      </div>
      <div>
        <!-- 验证码输入框 -->
        <van-password-input
          :value="value"
          :gutter="10"
          :length="6"
          :error-info="errorInfo"
          :mask="false"
          @focus="showKeyboard = true"
        />
      </div>
    </van-dialog>
    <!-- 数字键盘 -->
    <van-number-keyboard
      v-model="value"
      @show="handleKeyboardShow"
      :show="showKeyboard"
      @blur="showKeyboard = false"
    />
  </div>
</template>

<script>
import { getUserBlankCarId } from "@/untils/localStorage";
import { Toast} from "vant";
import {
  setWithdrawApply,
  setPayByBackSMS
} from "@/api/myWalletBeans/myWalletBeans";
export default {
  name: 'withdrawalBankDialog',
  props: {
    show: {
      type: Boolean,
      required: true
      },
      getCodeTextShow:{
        type: Boolean,
        required: true
      },
      modeFrom:{
        type:Object,
        default:()=>false
      },
      // token
      userToken:{
        type: String
      }
    }, 
  data() {
    return {
      visible: this.show,
      value:'',
      showKeyboard:false,
      setCodeTextFlage:true,
      textContent: "60秒",
      countdown: 60,
      errorInfo:'',
      setCodeTextShow:this.getCodeTextShow,
      costObject:{
        amount:'',
        bizOrderNo:'',
        fee:'',
        phone:'' ,// 手机号
        proceduresCharge:''
      },
    };
  },
  watch: {
    show() {
      this.visible = this.show
    },
    getCodeTextShow(){
      this.setCodeTextFlage = this.getCodeTextShow
    },
    // 监听密码框的值
    value(value) {
      if (value.length === 6) {
        // 传两个参数一个验证码一个订单号
        // this.userToken
        setPayByBackSMS(this.userToken,this.costObject.bizOrderNo,this.value).then(res=>{
          if(res.data.code == '10000' && res.data.subCode == 'OK'){
          this.$router.push({name:'sucessWithdrawalChange',query:{successObject:JSON.parse(res.data.data)}})
          }else{
            this.errorInfo='验证码输入错误,请重试' 
            // Notify({ type: "warning", message: res.data.subMsg || res.data.msg });
          }
          // 这边要携带值
          // 成功了跳转到成功页面
        }).catch(error=>{
          Toast.success('提取失败,请重试');
          this.value = ''
          // 验证码失败就清空验证码从新输入，或者关闭弹框
          return error
        })
      }
    },
  },
  created() {
    // 从父页面传手机号和验证码然后输入，在输入的最后完之后就立马发接口
    if(this.setCodeTextFlage){
      clearInterval(this.setCodeTextTimer);
      this.getCountdown()
    }
  },
  mounted() {
    // 做处理
    if(this.modeFrom){
        this.costObject.amount =  this.modeFrom.amount
        this.costObject.bizOrderNo =  this.modeFrom.bizOrderNo
        this.costObject.fee =  this.modeFrom.fee
        this.costObject.phone =  this.modeFrom.phone
        this.costObject.proceduresCharge =  this.modeFrom.proceduresCharge
    }
  },
  methods: {
    handleKeyboardShow(){
      const dialogElements = document.getElementsByClassName('van-dialog');

      // 遍历所有元素并进行偏移
      Array.from(dialogElements).forEach(dialogElement => {
          // 获取当前元素的原始 top 值
          const originalTop = parseInt(dialogElement.style.top) || 0;
          // 计算偏移后的 top 值，偏移量为原始 top 值的30%
          const offsetTop = originalTop + 200
          // 设置元素的新的 top 值
          dialogElement.style.top = offsetTop + 'px';
      });
// });
    },
    // 关闭
    closeDialog(){
      this.$emit('closeDialogShow', false)
      // 清空密码框
      this.value = ''
      clearInterval(this.setCodeTextTimer);
    },
    // 验证码计时
    getCountdown(){
            this.setCodeTextShow = false
            this.setCodeTextFlage = false;
            // 60S倒计时
            // 每秒减少倒计时，并更新页面显示
            clearInterval(this.setCodeTextTimer);
            this.setCodeTextTimer = setInterval(() => {
            this.setCodeTextFlage = false;
              this.countdown--;
              this.textContent = this.countdown + " 秒";
              // 倒计时结束
              if (this.countdown <= 0) {
                // 清除计时器
                clearInterval(this.setCodeTextTimer);
                // 启用发送验证码按钮
                // 重置倒计时时间
                this.countdown = 60;
                // 更新页面显示
                this.textContent = "";
                this.setCodeTextFlage = true;
              }
            }, 1000);
    },
    getCode(){
      setWithdrawApply(this.userToken,this.costObject.amount,getUserBlankCarId()).then(res=>{
        if(res.data.code == '10000' && res.data.subCode == 'OK'){
            // 去解析对象
            Toast.success('验证码获取成功,请输入');
            this.getCountdown()
          }else{
            Toast.fail('验证码获取失败');
          }
      }).catch(error=>{
        return error
      })
    }
  },
};
</script>

<style scoped lang="scss">
.van-dialog{
  padding: 10px 0;
  top:40%
}
.topDialog{
  padding: 0px 10px;
  text-align: right;
}
.topDialogCode{
  color: #222222;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  height: 20px;
  line-height: 20px;
}
.topDialogMain{
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
  .topDialogMain-Count{
    font-size: 28px !important;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
  }
}
.topDialogBottom{
  margin: 10px 0;
  display: flex;
  padding: 0 20px;
  font-size: 14px;
  color: #222222;
  justify-content: space-between;
  align-items: center;
  .topDialogBottom-left{
    color: #999999 !important;
  
  }
  .topDialogBottom-code{
    color: #FF6400;
    font-size: 12px;
  }
}
.van-password-input__item{
  background-color: #EDEDED;
}
.van-number-keyboard{
  z-index: 9999;
}
.van-password-input__item{
  width: 40px;
  height: 40px;
}
.btDisable {
  cursor: not-allowed;
}
.enable {
  pointer-events: none;
  color: #e2e2e2 !important;
}
</style>
