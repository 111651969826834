import request from '@/untils/request'
// 
// 余额查询
export function getWalletBalance(authorization) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      url: '/TongLianOrder/queryBalance',
      headers: {
        AuthorFlage:true,
        Authorization: authorization
      },
      data:{
        accountSetNo:'400406'
        // memberType:memberType
      }
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}
// 收支明细查询列表
export function getWalletBalanceDetaiList(authorization,dateStart,dateEnd,pageNo=1,pageSize=10) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      url: '/TongLianOrder/queryBalanceDetail',
      headers: {
        AuthorFlage:true,
        Authorization: authorization
      },
      data:{
        dateStart,
        dateEnd,
        pageNo,
        pageSize
      }
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}
// 余额明细列表详情接口
export function getWalletBalanceDetail(authorization,orderCode,amount,balance) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      url: '/TongLianOrder/queryBalanceListDetail',
      headers: {
        AuthorFlage:true,
        Authorization: authorization
      },
      data:{
        orderCode,
        amount,
        balance
      }
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}
// 查询绑定银行卡
export function getWalletBalanceCard(authorization) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      url: '/TongLianMember/queryBankCard',
      headers: {
        AuthorFlage:true,
        Authorization: authorization
      }
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}
// 绑定银行卡接口
export function setWalletBalanceCardId(authorization,data) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      url: '/TongLianMember/applyBindBankCard',
      headers: {
        AuthorFlage:true,
        Authorization: authorization
      },
      data
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}
// 提现余额
// withdrawalBank

export function setWithdrawApply(authorization,amount,bankCardNo) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      url: '/TongLianOrder/withdrawApply',
      headers: {
        AuthorFlage:true,
        Authorization: authorization
      },
      data:{
        amount,
        bankCardNo
      }
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}
// 提现余额最终确认
export function setPayByBackSMS(authorization,bizOrderNo,verificationCode) {
  // 返回一个 Promise 对象
  return new Promise((resolve, reject) => {
    // 发送网络请求
    request({
      method: 'POST',
      url: '/TongLianOrder/payByBackSMS',
      headers: {
        AuthorFlage:true,
        Authorization: authorization
      },
      data:{
        bizOrderNo,
        verificationCode
      }
    })
      .then(response => {
        // 如果请求成功，调用 resolve 将结果返回
        resolve(response);
      })
      .catch(error => {
        // 如果请求失败，调用 reject 将错误信息返回
        reject(error);
      });
  });
}

