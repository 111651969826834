<!-- 提现钱包页面 -->
<template>
  <div class="grindBeans">
    <div class="grindBeansFrom">
      <div class="grindBeansFrom-top">
        提现金额
        <div class="grindBeansFrom-main">
          <van-field 
          clearable 
          label="¥"
          type="number"
          placeholder="账户余额0.00" 
          v-model="withdrawalValue"
          >
            <template #button>
              <span
                class="allValue"
                @click="getAllCount"
                >全部</span>
            </template>
          </van-field>
        </div>
        <div class="grindBeansFrom-main">
          <van-field 
          v-if="showCode"
          type="number"
          label="验证码"
          placeholder="请输入验证码"
          v-model="code">
          </van-field>
        </div>
      </div>
    </div>
    <div style="padding: 0 10px">
      <van-button round block color="#FFA54F" @click="getWithdrawal"
        >提现</van-button
      >
    </div>
    <!-- 弹框 -->
    <withdrawalBankDialog
    :modeFrom="modeFrom"
    v-if="visible"
    :userToken="userToken"
    :getCodeTextShow="getCodeTextShow"
    @closeDialogShow="closeDialogShow"
    :show="visible"></withdrawalBankDialog>
  </div>
</template>

<script>
import withdrawalBankDialog from '../bankCardDeatil/withdrawalBankDialog.vue'
import {setWithdrawApply} from "@/api/myWalletBeans/myWalletBeans";
import { getUserBlankCarId } from "@/untils/localStorage";
import { Notify,Toast} from "vant";

export default {
  name: "bankCardDeatil",
  components: {
    withdrawalBankDialog
  },
  computed: {
    // 银行卡号
    BANK_ID(){
      return getUserBlankCarId() || ''
    }
  },
  data() {
    return {
      withdrawalValue: "",
      code:'',
      bankCardNo:'',
      showCode:false,
      sunmitShow: false,
      visible:false,
      getCodeTextShow:true,
      modeFrom:{},
      userToken:''
    };
  },
  created() {
  },
  mounted() {},
  methods: {
    // 获取全部提现金额
    getAllCount() {
      let { allAmount, freezenAmount } = this.$route.query.balanceDetails || '';
      this.withdrawalValue = allAmount - freezenAmount || '';
    },
    // 提交接口
    getWithdrawal() {
      if(this.withdrawalValue) {
        setWithdrawApply(this.$route.query.token,this.withdrawalValue,this.BANK_ID).then(res=>{
          if(res.data.code == '10000' && res.data.subCode == 'OK'){
            // 去解析对象
            this.visible = true
            this.getCodeTextShow = false
            this.userToken = this.$route.query.token
            this.modeFrom = JSON.parse(res.data.data)
          }else{
            this.visible =false
            this.getCodeTextShow = false
            Notify({ type: "warning", message: res.data.subMsg || res.data.msg });
          }
        }).catch(error=>{
          return error
        })
      }else{
        Toast('提现金额不能为空');
      }
    },
    // 子传父时间关闭弹框
    closeDialogShow(value){
      this.visible = value
    }
  },
};
</script>
<style scoped lang="scss">
.van-nav-bar__placeholder::v-deep .van-nav-bar {
  background-color: #fff5e2 !important;
}
.grindBeans {
  height: 100vh;
  overflow: hidden;
  background-color: rgb(239, 239, 239);
}
.van-button {
  margin-top: 40px;
}
.grindBeansFrom {
  position: relative;
  margin-top: 10px;
  padding: 20px 10px;
  background-color: #ffffff;
  .grindBeansFrom-top {
    font-size: 14px;
    text-align: left;
    font-weight: 600;
  }
  .grindBeansFrom-main {
    margin-top: 10px;
    display: flex;
    align-items: center;
    .allValue {
      font-size: 12px;
      font-weight: 400;
    }
 
    .van-cell{
      font-size: 14px;
      font-weight: 200;
    }
  }
  .grindBeansFrom-main:first-child{
    .van-cell::v-deep{
      .van-field__label{
        span{
          font-size: 24px; 
          font-weight: 600;
        }
      }
    }
  }
}

</style>
<style  lang="scss">
</style>
